import "../styles/Announcement.css";

const AnnouncementBar = ({ messages }) => {
  return (
    <div className="announcement-container">
      <div className="announcement-text">{messages[0]}</div>
      <div className="announcement-text">{messages[0]}</div>
      <div className="announcement-text">{messages[0]}</div>
    </div>
  );
};

export default AnnouncementBar;
