import React from 'react'
// import NavBar from '../components/NavBar'
// import Footer from '../components/Footer'
import NotFounds from '../components/NotFound'

const NotFound = () => {
  return (
    <React.Fragment>
        <NotFounds /> 
    </React.Fragment>
  )
}

export default NotFound
