import BlogCard from '../components/BlogCard';
import RecentPosts from '../components/RecentPosts';
import blogData from '../data/blogData'
import { Helmet } from 'react-helmet';

const BlogsCategory = () => {
    return (
        <>
        <Helmet>
        <title>Fire Kirin 777 Slots Blog - Expert Tips & Latest Casino Insights</title>
        <meta name="description" content="Stay updated with the latest casino trends, strategies, and tips at Fire Kirin 777 Slots. Explore expert articles, winning strategies, and exciting updates in the world of online slots and gaming." />
        <link rel="canonical" href="https://firekirin777slots.com/" />
        {/* OG Tags */}
        <meta property="og:title" content="Fire Kirin" />
        <meta property="og:site_name" content="Fire Kirin" />
        <meta property="og:url" content="https://firekirin777slots.com/" />
        <meta property="og:description"
          content="Fire Kirin is the #1 website for playing free online games on your mobile, tablet or computer. No need to download an app anymore—no login. Play now!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://firekirin777slots.com/assets/nav-logo.svg" />
      </Helmet>
        <section className='blog-category'>
            <div className='container'>
                <h1>Blogs</h1>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='row gy-4'>
                            {blogData.map((blog, index) => (
                                <BlogCard image={blog.image} title={blog.title} slug={blog.slug} />
                            ))}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='common-recent-detail'>
                            <RecentPosts />
                        </div>
                    </div>
                </div>

            </div>
        </section>
        </>
    )
}

export default BlogsCategory
