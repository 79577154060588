import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../components/Banner'
import Description from '../components/Description'
import Cards from '../components/Cards'
import FireKirin from '../components/FireKirin'
import Download from '../components/Download'
// import Carousel from '../components/Carousel'
import ExistingFeatures from '../components/ExistingFeatures'
import StartPlaying from '../components/StartPlaying'
import FaqSection from '../components/FaqSection'
const Home = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Download Fire Kirin Casino: Play Free Games & Win Big</title>
        <meta name="description"
          content="Download the Fire Kirin Casino app to enjoy a wide variety of free games! Experience the thrill of gaming anytime and discover opportunities to win big." />
        <link rel="canonical" href="https://firekirin777slots.com/" />
        {/* OG Tags */}
        <meta property="og:title" content="Fire Kirin" />
        <meta property="og:site_name" content="Fire Kirin" />
        <meta property="og:url" content="https://firekirin777slots.com/" />
        <meta property="og:description"
          content="Fire Kirin is the #1 website for playing free online games on your mobile, tablet or computer. No need to download an app anymore—no login. Play now!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://firekirin777slots.com/assets/nav-logo.svg" />
      </Helmet>
      <Banner />
      <Description />
      <ExistingFeatures />
      <Cards />
      <FireKirin />
      {/* <Carousel /> */}
      <StartPlaying />
      <Download />
      <FaqSection />
    </React.Fragment>
  )
}

export default Home
//       <Banner />
//       <Description />
//       <Cards />
//       <FireKirin />
//       <Carousel />
//       <Download />