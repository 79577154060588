// src/data/blogData.js

import Blog_1 from "../blogs/Blog_1";
const siteUrl = 'https://firekirin777slots.com';
let blogItems = [
    {
      id: '1',
      slug : 'tips-to-win-big-on-online-sweepstakes-slots' ,
      image : '/assets/carousel/sweepstakes-slots.png',

      metaTitle: 'Tips to Win Big on Online Sweepstakes Slots',
      title: 'Tips to Win Big on Online Sweepstakes Slots',
      content: 'Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!',
      metaDescription: 'Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!',
      canonical : siteUrl+'/blog/tips-to-win-big-on-online-sweepstakes-slots',

      ogTitle : 'Tips to Win Big on Online Sweepstakes Slots',
      ogDescription : 'Discover the best tips to maximize your chances of winning online sweepstakes and slots. Learn strategies, budget tips, and how to pick games with higher RTPs!',
      ogImage : siteUrl+'/assets/carousel/sweepstakes-slots.png',
      component : Blog_1 ,
      sharingUrl : siteUrl+'/blog/tips-to-win-big-on-online-sweepstakes-slots',
      date: '2024-11-25'
    },
  ];
  
  let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
  export default blogData;
  