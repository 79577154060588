import React from "react";
import "../styles/Banner.css";

const Banner = () => {
    return (
        <div className="banner-bg">
            <div className="banner-wrapper">
                <div className="the-ultimate-fish">
                    The Ultimate Fish Games and Slots
                </div>
                <h1 className="fire-kirin-online container_h1">Play Sweepstakes & Fish Games Anytime, Anywhere with Fire Kirin!</h1>
                <button
                    className="banner-button"
                    onClick={() =>
                        window.open("https://www.facebook.com/Firekirinonline77", "_blank")
                    }
                >
                    <span className="get-free-fire">Get Free Fire Kirin Account</span>
                </button>
                <div className="banner-container">
                    <div className="now-play">
                        NOW PLAY DIRECTLY WITH US!
                    </div>
                    {/* <div className="signup-txt">
                        Sign up today and recevive an incredible 200% Bonus on your first deposit!
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Banner;
