import React from "react";
import { Container, Row, Col, Card } from 'reactstrap';
import "../styles/ExistingFeatures.css";
import "../styles/StartPlaying.css";

const StartPlaying = () => {
    return (
        <React.Fragment>
            <div className="description-bg">
                <Container className="pt-5">
                    <h2 className="official-facebook-pages">How To Start Playing Sweepstakes Casino Games on Fire Kirin?</h2>
                    <div className="start-playing-listings">
                        Online Sweepstakes Casino games provide an immersive and interactive mobile sweepstakes experience that you can enjoy safely and comfortably from home. The objective is clear: the more you play, the more you win.
                    </div>
                    <div className="start-playing-listings">
                        Here are the steps to play Fire Kirin sweepstakes casino games:
                    </div>
                </Container>
                <Container className="d-flex justify-content-center py-sm-2 py-md-3 py-lg-2">
                    <Row>
                        <Col sm="12" md="6" className="card-gamming-wrapper">
                            <Card className="card-start-playing">
                                <h3 className="start-playing-text">FOR ONLINE</h3>
                                <div className="vip-gaming-is">
                                    <ol className="custom-ol">
                                        <li>Visit the website of Fire Kirin and create your free account.</li>
                                        <li>Signing up gives you a 200% bonus on your first deposit.</li>
                                        <li>Download the app and compete with other players, test your luck and skills, and earn rewards, all from your mobile phone or tablet.</li>
                                    </ol>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" md="6" className="card-gamming-wrapper">
                            <Card className="card-start-playing">
                                <h3 className="start-playing-text">FOR APP</h3>
                                <div className="vip-gaming-is">
                                    <ol className="custom-ol">
                                        <li>Download the app directly from the Play Store for Android devices. You can also download it from the App Store for your iOS devices.</li>
                                        <li>Explore the app and compete with other players to win more & more.</li>
                                    </ol>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
};

export default StartPlaying;
