import { FaFacebookMessenger } from "react-icons/fa6";
import '../styles/ChatMessenger.css'

const ChatMessenger = () => {

    const redirectToMessenger = () => {
        window.open('https://m.me/143334362192156', '_blank');
    };
    return (
        <div>
            <div className="messenger-icon" onClick={redirectToMessenger}>
                <FaFacebookMessenger size={'30px'} />
            </div>
        </div>
    )
}

export default ChatMessenger
