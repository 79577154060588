import React from "react";
import { Row, Col, Container, Card } from "reactstrap"
import "../styles/Description.css";

const Description = () => {
    return (
        <React.Fragment>
            <div className="description-bg">
                <Container>
                    <h2 className="what-exactly-is">
                    Why Choose Fire Kirin for Sweepstakes Casino Games?
                    </h2>
                    <div className="fire-kirin-is">
                    We offer many games, such as fish games, slots, and keno, guaranteeing many hours of fun and the chance of winning large. The key aspects that make us stand out are :
                    </div>
                    <Row className="variety-wrapper">
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="variety-of-games">
                            Wide Variety of Games
                            </div>
                            <div className="from-the-fast-paced">
                            Choose what you want to enjoy from the ocean of choices. From fish games to keno, we have something for everyone.
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="variety-of-games">
                                Play Anywhere
                            </div>
                            <div className="from-the-fast-paced">
                            Easy, seamless access from the comfort of your computer. One can download our Fire Kirin app directly to play free casino games.
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4}>
                            <div className="variety-of-games">
                                User-Friendly Experience
                            </div>
                            <div className="from-the-fast-paced">
                            Our platform was created with enthusiastic users like you in mind. It offers easy navigation, secure transactions, and top-notch customer support.
                            </div>
                        </Col>
                    </Row>
                </Container>
                <h2 className="official-facebook-pages py-4">OFFICIAL FACEBOOK PAGES</h2>
                <div className="please-contact-our-container">
                    <div>Please contact our official</div>
                    <div>Partners for account creation</div>
                </div>
                <Container className="d-flex justify-content-center py-sm-2 py-md-3 py-lg-5">
                    <Row>
                        <Col sm="12" md="6" className="card-gamming-wrapper">
                            <Card className="card-gamming">
                                <div className="vip-gaming">VIP GAMING</div>
                                <div className="vip-gaming-is">
                                    VIP Gaming is known for unmatched customer service, instant
                                    cash-in and cash-outs and 24/7 support. Join them and experience
                                    the thrill of online games.
                                </div>
                                <div className="facebook-fill">
                                    <img
                                        loading="lazy"
                                        alt=""
                                        src="/assets/icon/facebook-icon.png"
                                        onClick={() =>
                                            window.open(
                                                "https://www.facebook.com/profile.php?id=61551686566639&mibextid=ZbWKwL",
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12" md="6" className="card-gamming-wrapper">
                            <Card className="card-gamming">
                                <div className="vip-gaming">Firekirin SLOTS </div>
                                <div className="vip-gaming-is">
                                    Join Firekirin slots to receive 200% bonus on the first deposit.
                                </div>
                                <div className="facebook-fill">
                                    <img
                                        loading="lazy"
                                        alt=""
                                        src="/assets/icon/facebook-icon.png"
                                        onClick={() =>
                                            window.open(
                                                "https://www.facebook.com/Firekirinonline77?mibextid=ZbWKwL",
                                                "_blank"
                                            )
                                        }
                                    />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    )
};

export default Description;
