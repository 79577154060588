import React, { useState, useEffect } from 'react';
import { FaAngleUp } from "react-icons/fa";
import '../styles/ScrollButton.css';
import ChatMessenger from './ChatMessenger';

const ScrollButtons = () => {
  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='scroll-container'>
      <ChatMessenger />
      <button
        onClick={handleScrollToTop}
        className='scroll-button'
        style={{ display: atTop ? 'none' : 'block' }}
        disabled={atTop}
      >
        <FaAngleUp size='22px' />
      </button>
    </div>
  );
};

export default ScrollButtons;
