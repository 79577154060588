import React, { useState } from "react";
import { Container } from 'reactstrap';
import "../styles/FaqSection.css";

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is Fire Kirin?",
            answer: "Fire Kirin is a successful sweepstakes online gaming platform that allows one to play fish games, slots, and keno."
        },
        {
            question: "Why Choose Fire Kirin?",
            answer: "It contains many types of games, has an accessible interface, and is suitable for players with a computer or mobile phone at any location."
        },
        {
            question: "What are the advantages of using the Fire Kirin app?",
            answer: "The features of the Fire Kirin app include a choice of games, simplicity, and intuitive controls, improved graphics and sound, the ability to combine different gameplay features, social networking opportunities, and security that is advantageous over other gaming platforms."
        },
        {
            question: "What are the first steps to participate in games on Fire Kirin?",
            answer: "You can register a free account on the Fire Kirin website or download its application from the Play Store for Android devices and the App Store for iOS devices."
        },
        {
            question: "Do you get something extra for registration?",
            answer: "Getting a Fire Kirin account gives you a 200% bonus on your first deposit."
        },
        {
            question: "Are there mobile or tablet-compatible Fire Kirin games?",
            answer: "Of course, Fire Kirin games can be played on mobile phones or tablets using the downloadable application for Android and iOS devices."
        },
        {
            question: "Are Fire Kirin games fair?",
            answer: "Consequently, Fire Kirin employs a big random number generator to offer fair and random games to the players."
        },
        {
            question: "How does one get to beat Fire Kirin games?",
            answer: "The greater the number of Fire Kirin games you participate in, the higher your probability of winning. The games have play procedures and aims, so prospective players are advised to read and learn them."
        },
        {
            question: "How do I withdraw my winnings from Fire Kirin?",
            answer: "The Fire Kirin withdrawal process will also differ depending on the selected payment option among those mentioned earlier. For more details, kindly read the withdrawal policy."
        },
        {
            question: "Is Fire Kirin safe and secure?",
            answer: "Yes, Fire Kirin is safe and secure to use, and the team uses advanced technologies to encapsulate users’ details safely."
        },
    ];

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <React.Fragment>
            <div className="description-bg">
                <Container>

                    <h2 className="what-exactly-is">FAQs (Frequently Asked Questions)</h2>
                        {faqs.map((faq, index) => (
                            <div className="faq-container" key={index} onClick={() => toggleAnswer(index)}>
                                <div className="question">
                                    <span>{faq.question}</span>
                                    <span className={`arrow ${activeIndex === index ? "expanded" : ""}`}>{`>`}</span>
                                </div>
                                {activeIndex === index && (
                                    <div className="answer">
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}

                </Container>
            </div>
        </React.Fragment>
    );
};

export default FaqSection;
