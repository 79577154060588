import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import AnnouncementBar from "./Annoucement";
import ScrollButton from "./ScrollButton";
import { Outlet } from "react-router-dom";

const messages = [
  "Sign up today and receive an incredible 200% Bonus on your first deposit!",
  "Sign up today and receive an incredible 200% Bonus on your first deposit!",
];

const Layout = () => {
  return (
    <React.Fragment>
      <AnnouncementBar messages={messages} />
      <NavBar />
      <Outlet />
      <Footer />
      <ScrollButton />
    </React.Fragment>
  );
};

export default Layout;
