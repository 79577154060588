import React from "react";
import { Container } from "reactstrap"
import "../styles/ExistingFeatures.css";

const ExistingFeatures = () => {
    return (
        <React.Fragment>
            <div className="description-bg">
                <Container >
                    <h2 className="official-facebook-pages">Exciting Features on the Fire Kirin App</h2>
                    <div className="existing-features-listings">
                        Fire Kirin is one of the most popular sweepstakes gaming sites, providing players an exciting and engaging experience. Here are the exciting features of the app:
                    </div>
                    <div className="existing-features-listings">
                        <li> Diverse Game Selection</li>
                        <li> User-Friendly Interface</li>
                        <li> Enhanced Graphics and Audio</li>
                        <li> Seamless Gameplay Integration</li>
                        <li> Community and Social Features</li>
                        <li> Secure and Redivable Platform</li>
                    </div>

                </Container>
            </div>
        </React.Fragment>
    )
};

export default ExistingFeatures;
