import "../styles/Cards.css";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div className="banner-bg">
            <div className="banner-wrapper">
                <div className="fire-kirin-online">404</div>
                <div className="banner-container">
                    <div className="now-play">
                    THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST
                    </div>
                    <div className="get-free-fire">
                    <Link to='/'><button className="mx-3 nav-button">Please return to homepage</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NotFound;
